<template>
	<div>
		<!-- <button type="primary" @click="toProvider">连接</button>
		<button type="primary" @click="toSign">签名</button>
		<button type="primary" @click="toDisconnect">断开</button>
		<button type="primary" @click="toVerify">校验签名地址</button>
		<button type="primary" @click="toBalance">获取余额</button> -->
	</div>
</template>

<script>
import WalletConnectProvider from '@walletconnect/web3-provider';
import { providers } from 'ethers';
import { verifyMessage } from 'ethers/lib/utils';
import Web3 from 'web3';
export default {
	name: 'StatusContainer',
	data() {
		return {
			infuraId: '27e484dcd9e3efcfd25a83a78777cdf1',
			uri: '',
			address: '',
			provider: null,
			web3Provider: null
		};
	},
	created() {
		this.toProvider();
	},
	methods: {
		async toProvider() {
			this.provider = new WalletConnectProvider({
				infuraId: this.infuraId,
				qrcode: true
			});

			this.provider.connector.on('display_uri', (err, payload) => {
				this.uri = payload.params[0];
				console.log("local: ", this.uri);
				document.addEventListener('UniAppJSBridgeReady', () => {
					uni.postMessage({
						data: {
							action: {
								uri: this.uri
							}
						}
					});
				});
				uni.postMessage({
					data: {
						action: {
							uri: this.uri
						}
					}
				});
			});

			await this.provider.enable();

			this.web3Provider = new providers.Web3Provider(this.provider);
			const signer = await this.web3Provider.getSigner();
			const address = await signer.getAddress();
			const chainId = await this.provider.request({
				method: "eth_chainId"
			});

			this.address = address;
			uni.postMessage({
				data: {
					action: {
						address: this.address,
						chainId
					}
				}
			});
			
			this.provider.on("chainChanged", (chainId) => {
				console.log(chainId);
				uni.postMessage({
					data: {
						action: {
							address: this.address,
							chainId
						}
					}
				});
			});

			if (this.getQuery('disconnect') == 1) {
				this.toDisconnect();
			}

			if (this.getQuery('sign') == 1) {
				this.toSign();
			}

			if (this.getQuery('sign') == 2) {
				this.toCashSign();
			}
		},
		async toSign() {
			const signer = await this.web3Provider.getSigner();
			const message = `Welcome to StorageDAO for Web3!\n\nClick "Sign" to continue. \nThis signature will cost 0 gas.\n\nWallet address:\n${
				this.address
			}\n\nNonce:\n${this.getQuery('timestamp')}`;
			const signMessage = await signer.signMessage(message);
			uni.postMessage({
				data: {
					action: {
						signMessage
					}
				}
			});
		},
		async toCashSign() {
			const signer = await this.web3Provider.getSigner();
			const message = `You are initiating a withdrawal request.\n\nClick "Sign" to continue. \nThis signature costs no gas fee.\n\nWallet address:\n${
				this.address
			}\n\nNonce:\n${this.getQuery('timestamp')}`;
			const signMessage = await signer.signMessage(message);
			uni.postMessage({
				data: {
					action: {
						cashSignMessage: signMessage
					}
				}
			});
		},
		async toVerify() {
			const message = `Welcome to StorageDAO for Web3!\n\nClick "Sign" to continue. \nThis signature will cost 0 gas.\n\nWallet address:\n0x8A986bbD7F649d9D1804C2d6B941137358c8C4E4\n\nNonce:\n1666256584130`;
			const signMessage = '0xde74ba25d291f0b1ede78c251936fb4b7eba4a2fd3641215c4af529e63faccbf0662a1a88b239829e6fb78f0b3c83046f05ee49a3ef1491449de92f8dec5e3f61b';
			var recoveredAddress = verifyMessage(message, signMessage);
			console.log(recoveredAddress);
			alert(recoveredAddress);
		},
		async toBalance() {
			//https://ethereum-api.xyz/account-assets?address=0x70c805c2fD7f2c4D609380e2ac1239026b2590c5&chainId=1
		},
		async toDisconnect() {
			await this.provider.disconnect();
			this.address = '';
			this.uri = '';
			uni.postMessage({
				data: {
					action: {
						disconnect: true
					}
				}
			});
		},
		getQuery(name) {
			// 正则：[找寻'&' + 'url参数名字' = '值' + '&']（'&'可以不存在）
			let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
			let r = window.location.search.substr(1).match(reg);
			console.log(r);
			if (r != null) {
				// 对参数值进行解码
				return decodeURIComponent(r[2]);
			}
			return null;
		}
	}
};
</script>
