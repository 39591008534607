<template>
	<status-container />
</template>

<script>
import StatusContainer from './components/StatusContainer.vue';

export default {
	name: 'App',
	components: {
		StatusContainer
	}
};
</script>
